<template>
	<div class="trans_zong">
		<div class="trans_left">
			<div class="poo">订单管理</div>
			<div class="xiala_item">
				<div class="xiala_box" @click="changeTab(index)" :class="{'active':index===act}"
					v-for="(item,index) in list" :key="index">
					{{item}}
				</div>
			</div>
		</div>
		<div class="right1" v-if="act===0">
			<!-- 已卖出的宝贝 -->
			<OrderList />
		</div>
		<div class="right1" v-if="act===1">
			<!-- 退款管理 -->
			<Refund />
		</div>
		<div class="right1" v-if="act===2">
			<!-- 发货 -->
			<Delivergoods />
		</div>
		<div class="right1" v-if="act===3">
			<!-- 评价 -->
			<Comment />
		</div>
		<div class="right1" v-if="act===4">
			<!-- 投诉 -->
			<Complaint />
		</div>
	</div>

</template>

<script>
	import Order_list from '../order_list/order_list.vue'
	import Refund from '../refund/refund.vue'
	import Deliver_goods from '../deliver_goods/deliver_goods.vue'
	import Comment from '../comment/comment.vue'
	import Complaint from '../complaint/complaint.vue'

	export default {
		components: {
			OrderList: Order_list,
			Refund,
			Delivergoods: Deliver_goods,
			Comment,
			Complaint
		},
		data() {
			return {
				list: ["已卖出的宝贝", "退款管理", "发货", '评价', '投诉'],
				act: 0,
			}
		},
		methods: {
			changeTab(index) {
				this.act = index
			}
		}
	}
</script>

<style scoped>
	@import './transaction.css';
</style>
