<template>
	<div class="refund_zong">
		<template v-if="show_logistics===0">
			<div class="head">
				<!-- 头部tab栏 -->
				<div class="hd_tab">
					<div v-for="(item,index) in head_list" :key="index"
						:class="tab===index ? 'head_item_click' : 'head_item'" class="head_item"
						@click="change_item(index)">
						{{item.name}}
					</div>
				</div>
				<!-- 中间搜索框 -->
				<div class="hd_search">
					<div class="search_box">
						<div class="txt">订单号</div>
						<input class="inp" v-model="ord_id" />
					</div>
					<div class="search_box">
						<div class="txt">商品ID</div>
						<input class="inp" v-model="gods_id" />
					</div>
					<div class="search_box">
						<div class="txt">订单状态</div>
						<el-select class="select" v-model="value" placeholder="请选择">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="search_box">
						<div class="txt"></div>
						<el-button class="search_btn">搜索</el-button>
					</div>
					<div class="clear"></div>
				</div>
			</div>
			<!-- 批量操作商品 -->
			<div class="goods_edit">
				<el-button class="edit_box" :disabled='agree_apply'
					:class="agree_apply===false ? 'edit_box1' : 'edit_box'" @click="show_apply_tan">
					同意申请
				</el-button>
				<el-dialog class="bba1" center custom-class='dialog1' title="同意（未发货）" :visible.sync="apply_tan">
					<div class="dia_txt">确认要<span style="color:red;">退款</span>吗？</div>
					<div class="footer_btn">
						<el-button class="bt_cancel" @click="cancel_btn1">取消</el-button>
						<el-button class="bt_confirm">确定</el-button> <!-- 事件名已经在下面给定了-->
					</div>
				</el-dialog>
				<el-button class="edit_box" :disabled='agree_refund'
					:class="agree_refund===false ? 'edit_box1' : 'edit_box'" @click="show_refund_tan">
					确认退款
				</el-button>
				<el-dialog class="bba1" center custom-class='dialog1' title="同意申请" :visible.sync="refund_tan">
					<div class="dia_txt1">确认要<span style="color:red;">退款</span>吗？</div>
					<div class="dia_txt2">（货品反入仓检查后系统自动反买家）</div>
					<div class="footer_btn">
						<el-button class="bt_cancel" @click="cancel_btn2">取消</el-button>
						<el-button class="bt_confirm">确定</el-button> <!-- 事件名已经在下面给定了-->
					</div>
				</el-dialog>
				<div class="edit_hou">已选商品({{Select_goods_num}})</div>
			</div>
			<!-- 订单列表 -->
			<div class="aaa">
				<table class="table">
					<!-- 表头 -->
					<thead class="list_hd">
						<th class="one">
							<el-checkbox class="checkbox" @change="handleCheckAllChange" v-model="checkAll">
								宝贝
							</el-checkbox>
						</th>
						<th class="it">金额</th>
						<th class="it">原因</th>
						<th class="it">退款状态</th>
						<th class="it">退货状态</th>
						<th class="it">起止时间</th>
					</thead>
					<!-- 表单内容 -->
					<tbody class="list_text" v-for="(item,index) in order_list" :key="index">
						<tr>
							<!-- 订单号 -->
							<td class="gds_item">
								<el-checkbox class="checkbox" v-model="item.v1"
									@change="hra(item.v1,item.Return_status)">
									<img class="goods_tu" :src="item.img_url" />
								</el-checkbox>
								<div class="goods_info">
									<div class="Trade_name">{{item.Trade_name}}</div>
									<div class="goods_id">订单号:{{item.order_id}}</div>
									<div class="di">卖家ID：12345</div>
								</div>
							</td>
							<!-- 金额 -->
							<td>
								<div class="amount">￥{{item.price}}</div>
								<div class="refund_num" v-if="item.refund_state === '退款成功'">退款￥{{item.deliver_num}}
								</div>
							</td>
							<!-- 原因 -->
							<td class="reason">
								<div class="refund_reason">{{item.refund_reason}}</div>
							</td>
							<!-- 退款状态 -->
							<td class="re_state">
								<div class="refund_state"
									v-if="item.refund_state === '仅退款(未发货)' || item.refund_state === '退款(退货)'">
									{{item.refund_state}}</div>
								<div class="refund_state1" v-if="item.refund_state === '退款成功'">{{item.refund_state}}
								</div>
							</td>
							<!-- 退货状态 -->
							<td class="re_status">
								<template v-if="item.refund_state==='退款(退货)'">
									<div class="Return_status">{{item.Return_status}}</div>
									<div @click="change_logistics(item.Return_status)" class="Return_status1"
										v-if="item.Return_status === '已入库' || item.Return_status === '已经揽收'">
										<u>查看物流</u>
									</div>
								</template>
							</td>
							<!-- 起止时间 -->
							<td class="cr_time">
								<div class="creat_time">2021.12.27 13:47</div>
								<div class="creat_time" v-if="item.refund_state === '退款成功'">2021.12.27 13:47</div>
								<!-- 倒数计时 -->
								<template v-if="item.refund_state === '仅退款(未发货)' || item.refund_state === '退款(退货)'">
									<div class="countdown">
										<div class="time_box">{{h}}</div>
										<div class="fh">:</div>
										<div class="time_box">{{m}}</div>
										<div class="fh">:</div>
										<div class="time_box">{{s}}</div>
									</div>
								</template>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="footer">
				<div class="previous" @click="reduce">上一页</div>
				<div class="page_list">
					<div class="li_it" :class="page_tab === index ? 'li_it1' : 'li_it' " @click="change_page(index)"
						v-for="(item,index) in page_list" :key="index">
						{{item.page_num}}
					</div>
				</div>
				<div class="next" @click="add">下一页</div>
			</div>
		</template>
		<!-- 物流详情页 -->
		<template v-if="show_logistics===1">
			<div class="log_zong">
				<div class="log_hd">
					<div class="log_hd_title">物流详情</div>
					<div class="log_hd_text1">
						<div class="log_zuo">
							<div class="log_tui">买家已退货</div>
							<div class="log_tt">中通快递：75817892236188</div>
						</div>
						<div class="log_right">
							<div class="log_right_top">订单编号:2190349730441666526</div>
							<div class="log_right_bottom">揽货时间:2021-10-18 10:15:57 </div>
						</div>
					</div>
				</div>
				<div class="log_center">
					<div class="cen_title">包裹</div>
					<div class="cen_text">
						<div class="text_left">
							<img class="goods_tu" src="../../static/img/wxl_touxiang.jpg" />
							<div class="goods_tt">
								<div class="log_goods_name">商品名称商品名称商品名称商品名称名称商品名称商品名称商品名称...</div>
								<div class="log_goods_id">ID：12345678901</div>
								<div class="log_goods_num">X1</div>
							</div>
						</div>
						<div class="text_right" v-if="show_bt1==='已入库'">
							<el-button class="con" :disabled="show_bt" :class="show_bt===false ? 'con' : 'con1'"
								:plain="true" type="text" @click="confirm">
								确认收货
							</el-button>
							<el-button class="con" :disabled="show_bt" :class="show_bt===false ? 'con' : 'con1'"
								@click="refuse">
								拒绝退款
							</el-button>
							<el-dialog class="bba" center custom-class='dialog' title="拒绝退款" :visible.sync="bt_result">
								<div class="bba_main">物品损坏说明</div>
								<el-input class="el_inp" type="textarea" :rows="4" placeholder="请输入内容"
									v-model="textarea">
								</el-input>
								<div class="bba_img">物品损坏照片</div>
								<div class="img_list">
									<el-upload class="el_upload" action="https://jsonplaceholder.typicode.com/posts/"
										list-type="picture-card" :on-preview="handlePictureCardPreview"
										:on-remove="handleRemove">
										<i class="el-icon-plus"></i>
									</el-upload>
								</div>
								<div class="footer_btn">
									<el-button class="bt_cancel" @click="cancel_btn">取消</el-button>
									<el-button class="bt_confirm" @click="confirm_btn">确定</el-button>
								</div>
							</el-dialog>
						</div>
					</div>
				</div>
				<div class="log_footer">
					<div class="foo_title">物流动态</div>
					<div class="foo_text">
						<el-timeline class="timeline" :reverse="reverse">
							<el-timeline-item class="el_item" color="hsv" v-for="(activity, index) in activities"
								:key="index" :timestamp="activity.timestamp">
								{{activity.content}}
							</el-timeline-item>
						</el-timeline>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tab: 0, //头部选择退款状态
				ord_id: '', //订单号输入框
				gods_id: '', //商品ID输入框
				value: '', // 订单状态选中的值
				checkAll: false, // 宝贝的checkbox
				isIndeterminate: true, // 控制checkbox全选
				page_tab: 0, // 页码
				agree_apply: true, // 控制同意申请按钮
				apply_tan: false, // 控制同意申请弹框
				agree_refund: true, // 控制确认退款按钮
				refund_tan: false, // 控制确认退款弹框
				show_logistics: 0, // 控制物流页面
				show_bt: false, // 控制确认收货和拒绝退款按钮亮不亮
				show_bt1: '', // 根据退货状态决定按钮是否渲染
				bt_result: false, // 拒绝退款原因弹框
				textarea: '', //物品损坏说明
				Select_goods_num: 0, // 选中商品的数量
				h: '', //时 
				m: '', //分
				s: '', //秒
				seconds: 864000, // 10天的秒数
				head_list: [{
						id: 1,
						name: '全部订单'
					},
					{
						id: 2,
						name: '仅退款(未发货)'
					},
					{
						id: 3,
						name: '退款(退货)'
					},
				],
				options: [{
						value: '选项1',
						label: '仅退款'
					},
					{
						value: '选项2',
						label: '退款(已经发货)'
					},
				],
				order_list: [{
						order_id: 12345685421,
						v1: false, // checkbox是否选中
						Product_id: 12345621, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //卖家ID
						Trade_name: '商品名称商品名称商品名称商品名称商品名称商品撒旦发生名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "交易成功", // 订单状态
						refund_reason: '不想要了', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "仅退款(未发货)", // 退款状态
						Return_status: '', //退货状态
					},
					{
						order_id: 12345685421,
						v1: false, // checkbox是否选中
						Product_id: 12345621, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //卖家ID
						Trade_name: '商品名称商品名称商品名称商品名称商品名称商品撒旦发生名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "交易成功", // 订单状态
						refund_reason: '不想要了', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "退款成功", // 退款状态
						Return_status: '买家未收到货', //退货状态
					},
					{
						order_id: 12345685421,
						v1: false, // checkbox是否选中
						Product_id: 12345621, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //卖家ID
						Trade_name: '商品名称商品名称商品名称商品名称商品名称商品撒旦发生名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "交易成功", // 订单状态
						refund_reason: '不想要了', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "仅退款(未发货)", // 退款状态
						Return_status: '', //退货状态
					},
					{
						order_id: 12345685433,
						v1: false, // checkbox是否选中
						Product_id: 12345623, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //卖家ID
						Trade_name: '商品名称商品名称商品名称商品名称商品名称阿斯顿撒旦商品名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "待支付", // 订单状态
						refund_reason: '协商一致退货', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "退款(退货)", // 退款状态
						Return_status: '已经揽收', //退货状态
					},
					{

						order_id: 12345685422,
						v1: false, // checkbox是否选中
						Product_id: 12345621, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //卖家ID
						Trade_name: '商品名称商品名称商品名称商品名称阿斯顿撒旦品名称商品名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "交易取消", // 订单状态
						refund_reason: '不想要了', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "退款(退货)", // 退款状态
						Return_status: '已入库', //退货状态
					},
					{
						order_id: 12345685444,
						v1: false, // checkbox是否选中
						Product_id: 12345623, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //卖家ID
						Trade_name: '商品名称商品名称商品名撒大苏打称商品名称商品名称商品名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "交易成功", // 订单状态
						refund_reason: '协商一致退货', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "退款(退货)", // 退款状态
						Return_status: '等待卖家同意', //退货状态
					},
					{
						order_id: 12345685111,
						v1: false, // checkbox是否选中
						Product_id: 12345623, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //卖家ID
						Trade_name: '商品名称商品名称商品名撒大苏打称商品名称商品名称商品名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "交易成功", // 订单状态
						refund_reason: '协商一致退货', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "退款(退货)", // 退款状态
						Return_status: '等待卖家同意', //退货状态
					}
				],
				page_list: [{
						id: 1,
						page_num: '1'
					},
					{
						id: 2,
						page_num: '2'
					},
					{
						id: 3,
						page_num: '3'
					}, {
						id: 4,
						page_num: '4'
					}
				],
				reverse: false,
				activities: [{
						content: '快件已在枣庄潭州签收，签收人：本人。如有疑问请电话联系：15163265865，投诉电话：0632-4034981，您的快递已经投妥。风里来雨里去，只为客官您满意，上有老下有小，赏个好评好不好？【请在评价快递员处帮忙点亮五颗星星哦~】',
						timestamp: '2018-04-15'
					},
					{
						content: '枣庄潭州的问天分部李俊瑶[15163265865]正在派件 (95720为中通快递员外呼专属号码，请放心接听)',
						timestamp: '2018-04-13'
					},
					{
						content: '快件已到达枣庄潭州',
						timestamp: '2018-04-11'
					},
					{
						content: '快件离开济宁中专部已发往枣庄潭州',
						timestamp: '2018-04-11'
					},
					{
						content: '快件离开济宁中专部已发往枣庄潭州',
						timestamp: '2018-04-11'
					},
				]
			}
		},
		mounted() {
			this.Time() //调用定时器
		},
		methods: {
			// 天 时 分 秒 格式化函数
			countDown() {
				let d = parseInt(this.seconds / (24 * 60 * 60))
				d = d < 10 ? "0" + d : d
				let h = parseInt(this.seconds / (60 * 60));
				h = h < 10 ? "0" + h : h
				let m = parseInt(this.seconds / 60 % 60);
				m = m < 10 ? "0" + m : m
				let s = parseInt(this.seconds % 60);
				s = s < 10 ? "0" + s : s
				this.count = d + '天' + h + '时' + m + '分' + s + '秒'
				this.h = h
				this.m = m
				this.s = s
			},
			//定时器没过1秒参数减1
			Time() {
				setInterval(() => {
					this.seconds -= 1
					this.countDown()
				}, 1000)
			},
			change_item(index) { //头部宝贝状态的tab栏
				console.log('index', index)
				this.tab = index
			},
			handleCheckAllChange(val) { // 点击全选，
				console.log('val', val)
				this.isIndeterminate = false;
				if (!this.checkAll) {
					this.order_list.forEach((item) => {
						item.v1 = false
					})
					this.Select_goods_num = 0
					this.agree_apply = true
					this.agree_refund = true
				} else {
					this.order_list.forEach((item) => {
						item.v1 = true
						let aa = this.order_list.filter(item => item.v1 === true)
						this.Select_goods_num = aa.length
						this.agree_apply = false
						this.agree_refund = false
					})
				}
				// this.checkAll = !this.checkAll

			},
			change_page(index) { // 页码
				this.page_tab = index
			},
			reduce() { // 上一页按钮
				if (this.page_tab > 0) {
					this.page_tab -= 1
				}
			},
			add() { // 下一页按钮
				if (this.page_tab >= 0 && this.page_tab <= 2) {
					this.page_tab += 1
				}
			},
			hra(e, Return_status) { //选中其中一个退款订单，触发相对应订单能操作的按钮
				console.log('v1', e, Return_status)

				let aa = this.order_list.filter(item => item.v1 === true)
				console.log(aa.length === this.order_list.length)
				if (aa.length === this.order_list.length) {
					this.checkAll = true

				} else {
					this.checkAll = false
				}

				//选中的商品根据退货状态来判断同意申请按钮是否启用
				let mbv = this.order_list.filter(item => item.Return_status === '等待卖家同意')
				let mm = mbv.filter(item => item.v1 === true)
				if (mm.length > 0) {
					this.agree_apply = false
				} else {
					this.agree_apply = true
				}

				//选中的商品根据退货状态来判断确认退款按钮是否启用
				let xxx = this.order_list.filter(item => item.v1 === true && Return_status === '已经揽收' || item.v1 ===
					true && Return_status === '')
				if (xxx.length > 0) {
					this.agree_refund = false
				} else {
					this.agree_refund = true
				}

				// 选中商品的数量
				if (e === true) {
					this.Select_goods_num += 1
				} else {
					this.Select_goods_num -= 1
				}

			},
			change_logistics(e) { //改变控制显示查看物流页面的值
				console.log(e)
				this.show_bt1 = e
				this.show_logistics = 1
			},
			confirm() { //确认收货弹窗
				this.$message({
					message: '收货完成！',
					center: true,
					customClass: 'custon',
					type: 'success'
				});
				this.show_bt = !this.show_bt
			},
			refuse() { //拒绝退款弹窗
				this.bt_result = true
			},
			handleRemove(file, fileList) { // 上传图片
				console.log(file, fileList);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			confirm_btn() { // 拒绝退款弹框确定按钮事件
				this.show_bt = !this.show_bt
				this.bt_result = false
			},
			cancel_btn() { // 拒绝退款弹框取消按钮事件
				this.bt_result = false
			},
			show_apply_tan() { //同意申请按钮弹框事件
				this.apply_tan = !this.apply_tan
			},
			cancel_btn1() { //同意申请弹框取消按钮事件
				this.apply_tan = !this.apply_tan
			},
			// confirm_btn1(){ // 同意申请弹框确定按钮事件

			// },
			show_refund_tan() { // 确认退款按钮弹框事件
				this.refund_tan = !this.refund_tan
			},
			cancel_btn2() { // 确认退款弹框取消按钮事件
				this.refund_tan = !this.refund_tan
			},
			// confirm_btn2(){ //确认退款弹框确认按钮事件

			// },
		}
	}
</script>

<style scoped>
	@import './refund.css';

	.timeline /deep/ .el-timeline {
		width: 300px;
	}

	.timeline /deep/ .el-timeline-item {
		font-size: 15px;
	}

	.timeline /deep/ .el-timeline-item:nth-child(1) {
		font-weight: bold;
	}

	.bba /deep/ .el-dialog__header {
		padding: 15px 0;
	}

	.bba /deep/ .el-dialog__title {
		font-weight: bold;
		line-height: 0;
	}

	.bba /deep/ .el-dialog__body {
		padding: 20px 25px 30px;
		padding-top: 10px;
	}

	.bba1 /deep/ .el-dialog__title {
		font-size: 20px;
		font-weight: bold;
		line-height: 0;
	}

	.el_upload /deep/ .el-upload--picture-card {
		width: 100px;
		height: 100px;
		line-height: 100px;
	}

	.el_upload /deep/ .el-upload-list__item {
		width: 100px;
		height: 100px;
	}
</style>
