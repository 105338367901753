<template>
  <div ref="tree" class="order_zong">
    <template v-if="g_detail === 0">
      <div class="head">
        <!-- 头部tab栏 -->
        <div class="hd_tab">
          <div
            v-for="(item, index) in head_list"
            :key="index"
            :class="tab === index ? 'head_item_click' : 'head_item'"
            class="head_item"
            @click="change_item(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- 中间搜索框 -->
        <div class="hd_search">
          <div class="search_box">
            <div class="txt">订单号</div>
            <input class="inp" v-model="ord_id" />
          </div>
          <div class="search_box">
            <div class="txt">商品ID</div>
            <input class="inp" v-model="gods_id" />
          </div>
          <div class="search_box">
            <div class="txt"></div>
            <el-button class="search_btn" @click="search">搜索</el-button>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <!-- 批量操作商品 -->
      <div class="goods_edit">
        <el-button
          class="edit_box"
          :class="delete_btn === false ? 'edit_box1' : 'edit_box'"
          :disabled="delete_btn"
        >
          删除
        </el-button>
        <div class="edit_hou">已选商品({{ Select_goods_num }})</div>
      </div>
      <!-- 订单列表 -->
      <div class="hhh">
        <table class="table">
          <!-- 表头 -->
          <thead class="list_hd">
            <th class="one">
              <el-checkbox
                class="checkbox"
                @change="handleCheckAllChange"
                v-model="checkAll"
              >
                宝贝
              </el-checkbox>
            </th>
            <th class="it">价格</th>
            <th class="it">数量</th>
            <th class="it">订单状态</th>
            <th class="it">实收</th>
            <th class="it">创建时间</th>
            <th class="it">操作</th>
          </thead>
          <!-- 表单内容 -->
          <tbody
            class="list_text"
            v-for="(item, index) in order_list"
            :key="index"
          >
            <el-checkbox
              style="margin-left: 20px"
              v-model="item.v1"
              @change="hra(item.v1, item.status)"
            >
              <div>订单号：{{ item.ordersn }}</div>
            </el-checkbox>
            <tr v-for="(iitem, iindex) in item.list" :key="iindex">
              <td class="gds_item">
                <div class="checkbox">
                  <el-image
                      class="goods_tu"
                      :src="iitem.img_src"
                      :fit="'cover'"
                      :preview-src-list="[iitem.img_src]"
                      @click.prevent="a"
                    ></el-image>
                </div>
                <div class="goods_info">
                  <div class="Trade_name">{{ iitem.title }}</div>
                  <div class="goods_id">
                    商品ID:{{ iitem.goodsid}}
                  </div>
                  <div class="goods_id">
                    商品属性:{{ iitem.attribute_title }}
                  </div>
                  <div class="di">
                    <div>买家:</div>
                    <div
                      class="img"
                      :style="{ 'background-image': `url(${item.headimgurl})` }"
                    ></div>
                    <div class="name">{{ item.nickname }}</div>
                  </div>
                </div>
              </td>
              <td>
                <div class="price">￥{{ iitem.price }}</div>
              </td>
              <td>
                <div class="stock">{{ iitem.total }}</div>
              </td>
              <td :rowspan="`${item.list.length}`" v-if="iindex == 0">
                <div class="sales_volume">
                  <div class="jiaoyi" v-if="item.status == -1">交易取消</div>
                  <div class="jiaoyi" v-if="item.status == 0">待支付</div>
                  <div
                    class="jiaoyi"
                    v-if="
                      item.status == 1 || item.status == 2
                    "
                  >
                    <span>部分发货</span>
                  </div>
                  <div class="jiaoyi" v-if="item.status == 3">已发货</div>
                  <div class="jiaoyi" v-if="item.status == 4">交易成功</div>
                  <div class="jiaoyi" v-if="item.status == 5">退款成功</div>

                  <div
                    class="xiangqing"
                    v-if="item.status >= 0 && item.status < 5"
                    @click="change_detail(item.id)"
                  >
                    <u>详情</u>
                  </div>
                </div>
              </td>
              <td>
                <div class="vb">￥{{ iitem.price }}</div>
                <div class="vb1">（含快递费￥{{ iitem.dispatchprice }}）</div>
              </td>
              <td :rowspan="`${item.list.length}`" v-if="iindex == 0">
                <div class="creat_time">
                  <div class="time_txt">{{ item.time }}</div>
                  <template v-if="item.status == 0">
                    <div class="countdown">
                      <CusCountDown
                        :cTime="item.create_time"
                        @time_out="time_out(iitem.id)"
                      />
                    </div>
                  </template>
                </div>
              </td>
              <td>
                <div
                  class="look"
                  v-if="
                    (item.status == 2 && iitem.status == 1) ||
                    item.status == 3 ||
                    item.status == 4
                  "
                  @click="change_logistics"
                >
                  <u>查看物流</u>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <page-tab
        :page="page"
        :count="page_count"
        @change="change_page"
      ></page-tab>
    </template>
    <!-- 商品详情页 -->
    <template v-if="g_detail === 1">
      <div class="g_detail">
        <div class="act_icon iconfont icon-zuo" @click="back"></div>
        <div class="g_head">
          <div class="hd_title" v-if="order.status == 0">订单状态：待支付</div>
          <div class="hd_title" v-if="order.status == 1">订单状态：已支付</div>
          <div class="hd_title" v-if="order.status == 2">
            订单状态：部分发货
          </div>
          <div class="hd_title" v-if="order.status == 3">订单状态：已发货</div>
          <div class="hd_title" v-if="order.status == 4">
            订单状态：交易成功
          </div>
          <div class="hd_center">
            <el-steps
              class="steps"
              :space="150"
              size="larg"
              :active="status"
              :align-center="true"
              finish-status="success"
            >
              <el-step title="买家下单"></el-step>
              <el-step title="买家付款"></el-step>
              <el-step title="发货"></el-step>
              <el-step title="确认收货"></el-step>
            </el-steps>
          </div>
        </div>
        <div class="g_main">
          <div class="m1">
            <div class="m1_tt">订单信息</div>
            <div class="m1_text">订单编号:{{ order.ordersn }}</div>
            <div class="m1_text">创建时间:{{ order.time }}</div>
            <div class="m1_text" v-if="status > 1">
              付款时间:{{ order.pay_time }}
            </div>
            <div class="m1_text" v-if="status == 4">
              成交时间:{{ order.wctime }}
            </div>
          </div>
          <div class="m2">
            <div class="m1_tt">物流信息</div>
            <div class="m1_text">买家ID：{{order.userid}}</div>
            <div class="m1_text">
              <div
                class="name"
                v-if="
                  order.xname != '' &&
                  order.xname != null &&
                  order.xname != undefined
                "
              >
                {{ order.xname }}
              </div>
              <div class="name" v-else>{{ order.address_name }}</div>
              <div
                class="phone"
                v-if="
                  order.xphone != '' &&
                  order.xphone != null &&
                  order.xphone != undefined
                "
              >
                手机号码：{{ order.xphone }}
              </div>
              <div class="phone" v-else>
                手机号码：{{ order.address_phone }}
              </div>
            </div>
            <div class="m1_text">运单号：{{ order.expresssn }}</div>
            <div
              class="m1_text"
              v-if="
                order.xgaddress != '' &&
                order.xgaddress != null &&
                order.xgaddress != undefined
              "
            >
              收货地址：{{ order.xgaddress }}
            </div>
            <div class="m1_text" v-else>收货地址：{{ order.address }}</div>
          </div>
        </div>
        <div class="vvv">
          <table class="order_list">
            <!-- 表头 -->
            <thead class="list_hd">
              <th class="one">宝贝</th>
              <th class="it">价格</th>
              <th class="it">数量</th>
              <th class="it">订单状态</th>
              <th class="it">实收</th>
            </thead>
            <!-- 表单内容 -->
            <tbody class="list_text">
              <tr v-for="(aitem, index) in order.list" :key="index">
                <td class="gds_item">
                  <el-image
                      class="goods_tu"
                      :src="aitem.img_src"
                      :fit="'cover'"
                      :preview-src-list="[aitem.img_src]"
                      @click.prevent="a"
                    ></el-image>
                  <div class="goods_info">
                    <div class="Trade_name" :title="aitem.title">
                      {{ aitem.title }}
                    </div>
                    <div class="goods_id">
                      属性：{{ aitem.attribute_title }}
                    </div>
                    <div class="di">商品ID：{{ aitem.goodsid }}</div>
                  </div>
                </td>
                <td>
                  <div class="price">￥{{ aitem.price }}</div>
                </td>
                <td>
                  <div class="num">{{ aitem.total }}</div>
                </td>
                <td>
                  <!-- <div class="order_ty">确认收货</div> -->
                  <div class="order_ty" v-if="order.status == 0">待支付</div>
                  <div
                    class="order_ty"
                    v-if="
                      order.status == 1 ||
                      order.status == 2 ||
                      order.status == 3
                    "
                  >
                    <span v-if="aitem.status == 0">未发货</span>
                    <span v-if="aitem.status == 1">已发货</span>
                  </div>
                  <div class="order_ty" v-if="order.status == 4">交易成功</div>
                </td>
                <td>
                  <div class="vb">￥{{ aitem.price }}</div>
                  <div class="vb1">（含快递费￥{{ aitem.dispatchprice }}）</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>

    <!-- 物流详情页 -->
    <template v-if="g_detail === 2">
      <div class="logistics_zong">
        <div class="act_icon iconfont icon-zuo" @click="back"></div>
        <div class="log_head">
          <div class="log_hd_title">物流详情</div>
          <div class="log_hd_text1">
            <div class="log_zuo">
              <div class="log_tt">订单编号:2190349730441666526</div>
              <div class="log_tt">付款时间:2021-10-18 10:15:57</div>
            </div>
            <div class="log_right">
              <div class="log_right_top">
                <div class="log_tt">张三李四</div>
                <div class="log_tt">手机号码：13969451311</div>
              </div>
              <div class="log_right_bottom">
                收货地址：广东省广州市番禺区迎星中路骏盈大厦骏盈大路骏
              </div>
            </div>
          </div>
        </div>
        <div class="log_center">
          <div class="cen_title">包裹</div>
          <div class="cen_text">
            <div class="text_left">
              <div class="left_txt">运单号:2190349730441666526</div>
              <div class="left_txt">中通快递：75817892236188</div>
              <div class="left_txt">发货时间:2021-10-18 10:15:57</div>
            </div>
            <div class="text_right">
              <img class="goods_tu" src="../../static/img/wxl_touxiang.jpg" />
              <div class="goods_tt">
                <div class="log_goods_name">
                  商品名称商品名称商品名称商品名称名称商品名称商品名称商品名称...
                </div>
                <div class="log_goods_id">ID：12345678901</div>
                <div class="log_goods_num">X1</div>
              </div>
            </div>
          </div>
        </div>
        <div class="log_footer">
          <div class="foo_title">物流动态</div>
          <div class="foo_text">
            <el-timeline class="timeline" :reverse="reverse">
              <el-timeline-item
                class="el_item"
                color="hsv"
                v-for="(activity, index) in activities"
                :key="index"
                :timestamp="activity.timestamp"
              >
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "../../common/axios";
import CusCountDown from "../from/cus-countDown.vue";
import PageTab from "../pages/index";

export default {
  components: {
    CusCountDown,
    PageTab,
  },
  data() {
    return {
      tab: 0, //选择宝贝状态
      checkAll: false, // 订单号的checkbox
      goods_type: 0, //商品状态
      g_detail: 0, // 商品详情
      delete_btn: true, // 控制删除按钮
      Select_goods_num: 0, // 选中商品的数量
      ord_id: "",
      gods_id: "",
      page: 1,
      page_count: 0,
      order_id: "",
      order: {},
      status: 0,
      head_list: [
        {
          id: 1,
          name: "已卖出的宝贝",
        },
        {
          id: 2,
          name: "交易成功",
        },
        {
          id: 3,
          name: "交易取消",
        },
        {
          id: 4,
          name: "待付款",
        },
      ],

      order_list: [
        {
          order_id: 12345685421,
          v1: false, // checkbox是否选中
          Product_id: 12345621, //商品ID
          real_income: 180, // 实收
          freight: "0.00", // 运费
          Buyer_id: 123456, //买家ID
          Trade_name:
            "商品名称商品名称商品名称商品名称商品名称商品撒旦发生名称商品名sadsadsadsa称商品名称...",
          img_url: require("../../static/img/wxl_touxiang.jpg"), //商品图片
          price: 180, //商品价格
          deliver_num: 180, // 退款金额
          stock: 1, // 商品数量
          order_type: "交易成功", // 订单状态
          refund_reason: "不想要了", // 退款原因
          is_refund: 0, //0:未退款  1：已发起退款申请
          refund_state: "仅退款(未发货)", // 退款状态
          Return_status: "", //退货状态
          Distribution_status: "已发货", // 配送状态
          Express_type: "邮政快递", // 快递类型
          courier_number: "654651515484342", //快递单号
          phone: 13005001822, // 收件人手机号
          addressee: "龟仙人", //收件人
          Receiving_address: "广东省广州市番禺区迎星中路骏盈大厦骏盈大路骏", //收货地址
          first_evaluate:
            "物流满分包装满分快递心物流满分包装满分快满分购物让人开心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递满分购物让人开心", //买家评论
          first_evaluate_time: "2021-10-11 15:12", //初次评价时间
          Additional_evaluation:
            "物流满分包装满分快递满分购物让人开心物流满分包装满分快递满分购物流满分包装满分快递满分购物让人开心", //追加评价
          Additional_evaluation_time: "2021-10-11 15:12", // 追加评价时间
          Recoverable_time: "30", // 可回复剩余时间
          v2: true, // 买家是否已评价
          v3: false, //卖家是否已回复评价
          v4: true, // 是否有追加评论
          Compensation_num: 180, //赔付金额
          complaint_reason: "发货问题", //投诉原因
          Complaint_status: "等待卖家处理", //投诉状态
          apply_time: "2021.12.27 13:47", // 投诉申请时间
          god_message: "全部商品", //活动商品信息
        },
      ],

      reverse: false,
      activities: [
        {
          content:
            "快件已在枣庄潭州签收，签收人：本人。如有疑问请电话联系：15163265865，投诉电话：0632-4034981，您的快递已经投妥。风里来雨里去，只为客官您满意，上有老下有小，赏个好评好不好？【请在评价快递员处帮忙点亮五颗星星哦~】",
          timestamp: "2018-04-15",
        },
        {
          content:
            "枣庄潭州的问天分部李俊瑶[15163265865]正在派件 (95720为中通快递员外呼专属号码，请放心接听)",
          timestamp: "2018-04-13",
        },
        {
          content: "快件已到达枣庄潭州",
          timestamp: "2018-04-11",
        },
        {
          content: "快件离开济宁中专部已发往枣庄潭州",
          timestamp: "2018-04-11",
        },
        {
          content: "快件已到达枣庄潭州",
          timestamp: "2018-04-11",
        },
        {
          content: "快件离开济宁中专部已发往枣庄潭州",
          timestamp: "2018-04-11",
        },
      ],
    };
  },
  mounted() {
    this.get_list();
  },
  methods: {
    //返回上一页
    back() {
      this.g_detail = 0;
    },
    change_page(e) {
      this.page = e.value;
      this.get_list(e.value);
      this.$refs.tree.scrollTop = 0
    },
    time_out(e) {
      console.log(e);
    },
    get_list(page = 1) {
      let type = this.tab;
      this.page = page;
      let status = "";
      if (type == 1) {
        status = 4;
      } else if (type == 2) {
        status = -1;
      } else if (type == 3) {
        status = 0;
      }
      let data = {
        page,
        status,
        ord: this.ord_id,
        title: this.gods_id,
      };
      axios.post("/adminshop/order/index", data).then((res) => {
        var reslut = res.data;
        if (res.success) {
          this.order_list = reslut.list;
          this.page_count = reslut.pages;
        }
      });
    },
    change_item(index) {
      //头部宝贝状态的tab栏
      this.tab = index;
      this.get_list();
    },
    handleCheckAllChange(val) {
      // 点击全选，
      console.log("val", val);
      if (!this.checkAll) {
        this.order_list.forEach((item) => {
          item.v1 = false;
        });
        this.Select_goods_num = 0;
        this.delete_btn = true;
      } else {
        this.order_list.forEach((item) => {
          item.v1 = true;
        });
        let aa = this.order_list.filter((item) => item.v1 === true);
        this.Select_goods_num = aa.length;
        this.delete_btn = false;
      }
    },
    // 控制订单详情页是否渲染
    change_detail(id) {
      this.g_detail = 1;
      let data = {
        id,
      };
      axios.post("/adminshop/order/get_order", data).then((res) => {
        var reslut = res.data;
        if (res.success) {
          if (reslut.list.status == 0) {
            this.status = 1;
          }
          if (reslut.list.status == 1) {
            this.status = 2;
          }
          if (reslut.list.status == 2) {
            this.status = 3;
          }
          if (reslut.list.status == 3) {
            this.status = 3;
          }
          if (reslut.list.status == 4) {
            this.status = 4;
          }
          this.order = reslut.list;
          console.log(reslut);
        }
      });
    },
    change_logistics() {
      // 控制物流详情页是否渲染
      this.g_detail = 2;
    },
    hra(e) {
      //选中其中一个退款订单，触发相对应订单能操作的按钮
      this.delete_btn = !this.delete_btn;

      let aa = this.order_list.filter((item) => item.v1 === true);
      console.log(aa.length === this.order_list.length);
      if (aa.length === this.order_list.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }

      //选中的商品删除按钮是否启用
      let xxx = this.order_list.filter((item) => item.v1 === true);
      if (xxx.length > 0) {
        this.delete_btn = false;
      } else {
        this.delete_btn = true;
      }

      // 选中商品的数量
      if (e === true) {
        this.Select_goods_num += 1;
      } else {
        this.Select_goods_num -= 1;
      }
    },
    search() {
      this.get_list();
    },
    a(){}
  },
};
</script>

<style scoped>
@import "./order_list.css";

.steps /deep/ .el-step__icon {
  width: 50px;
  height: 50px;
}

.steps /deep/ .el-step__line {
  top: 50%;
  transform: translateY(-50%);
}

.steps /deep/ .is-success {
  color: #a466fd;
  font-weight: bold;
  border-color: #a466fd;
}

.steps /deep/ .is-process {
  color: #999999;
  border-color: #999999;
}

.steps /deep/ .is-status {
  transform: scale(2.5);
}

.timeline /deep/ .el-timeline {
  width: 300px;
}

.timeline /deep/ .el-timeline-item {
  font-size: 15px;
}

.timeline /deep/ .el-timeline-item:nth-child(1) {
  font-weight: bold;
}
</style>
