<template>
    <div class="countdown">
        <div class="time_box">{{h}}</div>
        <div class="fh">:</div>
        <div class="time_box">{{m}}</div>
        <div class="fh">:</div>
        <div class="time_box">{{s}}</div>
    </div>
</template>

<script>
export default {
    props:{
        cTime:{
            type: [Number,String],
            default: 0
        }
    },
    data(){
        return {
            snap_time:'',
            h:'',
            m:'',
            s:'',
        }
    },
    mounted(){
        this.leftTimer(this.cTime*1000)  
    },
    methods:{
        leftTimer(cTime) {
            let a = new Date(parseInt(cTime))
            let leftTime =a - new Date(); //计算剩余的毫秒数
            let time2 = new Date().getTime();
            let hours = parseInt(leftTime / 1000 / 60 / 60 % 24, 10);
            let minutes = parseInt(leftTime / 1000 / 60 % 60, 10); //计算剩余的分钟 
            let seconds = parseInt(leftTime / 1000 % 60, 10); //计算剩余的秒数
            this.h = this.checkTime(hours);
            this.m = this.checkTime(minutes);
            this.s = this.checkTime(seconds);
            let bb =''
            if (time2 < cTime) {
                if (hours > 0) {
                    this.snap_time = this.h + ":" + this.m + ":" + this.s;
                } else {   
                    this.snap_time = this.m + ":" + this.s;
                }
                bb = setTimeout(() => {
                    this.leftTimer(cTime);
                }, 1000)
            } else {
                this.snap_time = "";
                clearTimeout(bb)
                this.$emit('time_out')
            }
        },
        checkTime(i) { //将0-9的数字前面加上0，例1变为01
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        },
    },
}
</script>

<style scoped>
     .countdown{
        padding-top: 10px;
        display: flex;
        justify-content: center;
    }
    .fh{
        padding: 5px 5px;
    }
    .time_box{
        font-size: 15px;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        border: 1px solid #A466FD;
    }
</style>>
