<template>
  <div ref="tree" class="comment_zong">
    <div class="head">
      <!-- 头部tab栏 -->
      <div class="hd_tab">
        <div
          v-for="(item, index) in head_list"
          :key="index"
          :class="tab === index ? 'head_item_click' : 'head_item'"
          class="head_item"
          @click="change_item(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 中间搜索框 -->
      <div class="hd_search">
        <div class="search_box">
          <div class="txt">订单号</div>
          <input class="inp" v-model="ord_id" />
        </div>
        <div class="search_box">
          <div class="txt">商品ID/商品名</div>
          <input class="inp" v-model="gods_id" />
        </div>
        <div class="search_box">
          <div class="txt"></div>
          <el-button class="search_btn" @click="search">搜索</el-button>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <div class="bbb">
      <table class="atable" v-for="(item, index) in order_list" :key="index">
        <!-- 表头 -->
        <thead class="list_hd">
          <th class="one">
            <div class="checkbox">订单号：{{ item.ordersn }}</div>
            <!-- <div class="it">买家：{{ item.userid }}</div> -->
            <div class="it">
              <div>买家：</div>
              <img class="headimg" :src="item.headimgurl" alt="">
              <div class="name">{{item.nickname}}</div>
            </div>

          </th>
        </thead>
        <!-- 表单内容 -->
        <tbody
          class="list_text"
          v-for="(aitem, aindex) in item.list"
          :key="aindex"
          style="border: 1px solid #ccc"
        >
            <!-- 如果买家已评论 -->
            <template v-if="aitem.ping != null && aitem.ping != undefined">
              <tr>
                <td class="gds_item">
                  <div class="item_zong">
                    <div class="item_zuo">
                      <div class="zuo_txt1">{{ aitem.ping.content }}</div>
                      <div class="goods_tu">
                        <div
                          class="tu1"
                          v-for="(bitem, bindex) in aitem.ping.com_src"
                          :key="bindex"
                          :style="{
                            'background-image': `url(${bitem.img_src})`,
                          }"
                          @click="look_img(aitem.ping.com_src,bindex)"
                        >
                          <div
                            v-if="bitem.type == 1"
                            :style="{
                              'background-image': `url(${
                                websiteUrl +
                                '/public/static/weixin/img/wxl_ah_bofang.png'
                              })`,
                            }"
                            class="bofang"
                          ></div>
                        </div>
                      </div>
                      <el-rate v-model="aitem.ping.star" disabled></el-rate>
                      <div class="zuo_txt2">
                        初次评价：{{ aitem.ping_time }}
                      </div>
                      <!-- 卖家如果已回复评论就显示 -->
                      <div class="uuo" v-if="aitem.ping.reply_id > 0">
                        <div class="uuo_1">
                          商家回复：{{ aitem.ping.reply_time }}
                        </div>
                        <div class="uuo_2">
                          {{ aitem.ping.reply }}
                        </div>
                      </div>
                    </div>
                    <div class="item_zhong">
                      <!-- <div class="you_txt1">{{ aitem.title }}</div>
                      <div class="you_txt2">￥{{ aitem.price }}</div> -->
                      <div class="goods_item">
                        <el-image class="goods_img" :src="aitem.img_src"  :fit="'cover'" :preview-src-list="[aitem.img_src]"></el-image>
                          <div class="goods_them">
                            <div class="goods_title" :title="aitem.title">{{ aitem.title }}</div>
                            <div style="margin:10px 0">商品ID：{{ aitem.goodsid }}</div>
                            <div>商品属性：{{ aitem.attribute_title }}</div>
                          </div>
                      </div>
                      <div class="you_txt3" v-if="aitem.ping.is_reply == 0">
                        剩余可回复时间：{{ aitem.ping.reply_day }}天
                      </div>
                    </div>
                    <div
                      class="item_you"
                      v-if="aitem.ping.is_reply == 0"
                      @click="show_reply_comment(aitem.ping.id)"
                    >
                      <u>评价回复</u>
                    </div>
                    <div class="item_you1" v-if="aitem.ping.is_reply == 1">
                      已回复
                    </div>
                    <div class="item_you1" v-if="aitem.ping.is_reply == 2">
                      已过期
                    </div>
                  </div>
                </td>
              </tr>
              <!-- 如果买家有追加评论则显示 -->
              <template v-if="aitem.zping != null && aitem.zping != undefined">
                <tr>
                  <div class="oko">
                    <div class="oko_left">
                      <div class="left_txt1">
                        {{ aitem.zping.content }}
                      </div>
                      <div class="goods_tu">
                        <div
                          class="tu1"
                          v-for="(bitem, bindex) in aitem.zping.com_src"
                          :key="bindex"
                          :style="{
                            'background-image': `url(${bitem.img_src})`,
                          }"
                           @click="look_img(aitem.zping.com_src,bindex)"
                        >
                          <div
                            v-if="bitem.type == 1"
                            :style="{
                              'background-image': `url(${
                                websiteUrl +
                                '/public/static/weixin/img/wxl_ah_bofang.png'
                              })`,
                            }"
                            class="bofang"
                          ></div>
                        </div>
                      </div>
                      <div class="left_txt2">
                        追加评价：{{ aitem.zping_time }}
                      </div>
                      <div class="uuo" v-if="aitem.zping.reply_id > 0">
                        <div class="uuo_1">
                          商家回复：{{ aitem.zping.reply_time }}
                        </div>
                        <div class="uuo_2">
                          {{ aitem.zping.reply }}
                        </div>
                      </div>
                    </div>
                    <div class="sd"></div>
                    <div
                      class="oko_right"
                      v-if="aitem.zping.is_reply == 0"
                      @click="show_reply_comment(aitem.zping.id)"
                    >
                      <u>评价回复</u>
                    </div>
                    <div class="oko_right1" v-if="aitem.zping.is_reply == 1">
                      已回复
                    </div>
                    <div class="oko_right1" v-if="aitem.zping.is_reply == 2">
                      已过期
                    </div>
                  </div>
                </tr>
              </template>
            </template>
            <!-- 买家未及时评论，系统已自动评论 -->
            <template v-else>
              <tr>
                <td class="gds_item">
                  <div class="item_zong">
                    <div class="item_zuo">
                      <div class="zuo_txt1">
                        评价方未及时做出评价，系统默认好评！
                      </div>
                      <div class="goods_tu1 iconfont icon-like-full"></div>
                      <div class="zuo_txt2">
                        确认订单时间：{{ item.wctime }}
                      </div>
                    </div>
                    <div class="item_zhong">
                      <div class="you_txt1">{{ aitem.title }}</div>
                      <div class="you_txt2">￥{{ aitem.price }}</div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
			<tr>
				<div class="trab"></div>
			</tr>
        </tbody>
      </table>
    </div>
     <page-tab
        :page="page"
        :count="page_count"
        @change="change_page"
      ></page-tab>
    <el-dialog
      class="bba"
      center
      custom-class="dialog"
      title="回复评论"
      :visible.sync="reply_comment"
    >
      <div class="bba_main">
        <el-input
          type="textarea"
          :rows="7"
          placeholder="请输入评论"
          v-model="textarea"
        >
        </el-input>
      </div>
      <div class="footer_btn">
        <el-button class="bt_cancel" @click="cancel_btn">取消</el-button>
        <el-button class="bt_confirm" @click="huifu">确定</el-button>
        <!-- 事件名已经在下面给定了-->
      </div>
    </el-dialog>
    <Preview ref="preview"> </Preview>
  </div>
</template>

<script>
import axios from "../../common/axios";
import helper from "../../common/helper";
import PageTab from "../pages/index";
import Preview from "../preview/index.vue"

export default {
  components: {
    PageTab,
    Preview
  },
  data() {
    return {
      page: 1,
      page_count: 0,
      order_id:"",//评论id

      tab: 0, //头部选择退款状态
      ord_id: "", //订单号输入框
      gods_id: "", //商品ID输入框
      Confirm_shipment: true, // 控制回复按钮
      reply_comment: false, //控制回复评价弹框
      textarea: "", // 回复评论弹框输入框的值
      websiteUrl: helper.websiteUrl,
      head_list: [
        {
          id: 1,
          name: "全部评价",
        },
        {
          id: 2,
          name: "图片/视频",
        },
        {
          id: 3,
          name: "待评价",
        },
        {
          id: 4,
          name: "追评",
        },
        {
          id: 5,
          name: "好评",
        },
        {
          id: 6,
          name: "中/差评",
        },
      ],
      order_list: [],
    };
  },
  mounted() {
    this.get_list();
  },
  methods: {
    look_img(src_img,index){
      this.$refs.preview.show(src_img,index)
    },
    get_list(page = 1) {
      let type = this.tab;
      this.page =page
      let data = {
        page,
        type,
        ord:this.ord_id,
        title:this.gods_id
      };
      axios.post("/adminshop/order/comment_list", data).then((res) => {
        var reslut = res.data;
        
        if (res.success) {
          this.order_list = reslut.list;
          console.log(this.order_list);
          this.page_count = reslut.pages;
        }
      });
    },
     change_page(e) {
      this.page = e.value;
      this.get_list(e.value);
      this.$refs.tree.scrollTop = 0
    },
    change_item(index) {
      //头部宝贝状态的tab栏
      this.tab = index;
       this.get_list();
    },

    show_reply_comment(id) {
      // 显示回复评价按钮弹框
      this.reply_comment = !this.reply_comment;
      this.order_id = id
    },
    cancel_btn() {
      // 回复评价弹框取消按钮事件
      this.reply_comment = !this.reply_comment;
    },
    //回复
    huifu(){
      let data = {
        id:this.order_id,
        text:this.textarea
      };
      axios.post("/adminshop/order/add_comment", data).then((res) => {
        var reslut = res.data;
        if (res.success) {
          console.log(reslut);
          if(reslut.status == 1){
            this.textarea =''
            this.reply_comment = false
            this.$message({
              message: reslut.msg,
              type: "success",
            });
            this.get_list(this.page);
          }else{
            this.$message.error(reslut.msg);
          }
        }
      });
    },
    search(){
      this.get_list()
    },
  },
};
</script>

<style scoped>
@import "./comment.css";

.bba /deep/ .el-dialog__header {
  padding: 15px 0;
}

.bba /deep/ .el-dialog__title {
  font-weight: bold;
  line-height: 0;
}
</style>
