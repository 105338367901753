<template>
  <div ref="tree" class="deliver_zong">
    <template v-if="show_logistics === 0">
      <div class="head">
        <!-- 头部tab栏 -->
        <div class="hd_tab">
          <div
            v-for="(item, index) in head_list"
            :key="index"
            :class="tab === index ? 'head_item_click' : 'head_item'"
            class="head_item"
            @click="change_item(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- 中间搜索框 -->
        <div class="hd_search">
          <div class="search_box">
            <div class="txt">订单号</div>
            <input class="inp" v-model="ord_id" />
          </div>
          <div class="search_box">
            <div class="txt">商品ID/商品名</div>
            <input class="inp" v-model="gods_id" />
          </div>
          <div class="search_box">
            <div class="txt"></div>
            <el-button class="search_btn" @click="search">搜索</el-button>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="bbb">
        <table class="table">
          <!-- 表头 -->
          <thead class="list_hd">
            <th class="one">
              <div class="checkbox">宝贝</div>
            </th>
            <th class="it">金额</th>
            <th class="it">数量</th>
            <th class="it">下单时间</th>
            <th class="it">支付时间</th>
            <th class="it">配送状态</th>
            <th class="it caoz">操作</th>
          </thead>
          <!-- 表单内容 -->
          <tbody
            class="list_text"
            v-for="(item, index) in order_list"
            :key="index"
          >
            <tr>
              <div style="margin-left: 20px">订单号：{{ item.ordersn }}</div>
            </tr>
            <tr v-for="(iitem, iindex) in item.list" :key="iindex">
              <template
                v-if="
                  (tab == 1 && iitem.status == 0) ||
                  (tab == 2 && iitem.status == 1) ||
                  tab == 0
                "
              >
                <td class="gds_item">
                  <div class="checkbox">
                    <el-image
                      class="goods_tu"
                      :src="iitem.img_src"
                      :fit="'cover'"
                      :preview-src-list="[iitem.img_src]"
                      @click.prevent="a"
                    ></el-image>
                  </div>
                  <div class="goods_info">
                    <div class="Trade_name" :title="iitem.title">
                      {{ iitem.title }}
                    </div>
                    <div class="goods_id">
                      商品ID:{{ iitem.goodsid }}
                    </div>
                    <div class="goods_id">
                      商品属性:{{ iitem.attribute_title }}
                    </div>
                    <div class="di">
                      <div>买家:</div>
                      <div
                        class="img"
                        :style="{
                          'background-image': `url(${item.headimgurl})`,
                        }"
                      ></div>
                      <div class="name">{{ item.nickname }}</div>
                    </div>
                  </div>
                </td>
                <!-- 金额 -->
                <td>
                  <div class="amount">￥{{ iitem.price }}</div>
                </td>
                <!-- 数量 -->
                <td>
                  <div style="text-align: center">{{ iitem.total }}</div>
                </td>
                <!-- 下单时间 -->
                <td
                  class="xd_time"
                  :rowspan="`${item.list.length}`"
                  v-if="iindex == 0"
                >
                  <div class="xiadan_time">{{ item.time }}</div>
                </td>
                <!-- 支付时间 -->
                <td
                  class="py_time"
                  :rowspan="`${item.list.length}`"
                  v-if="iindex == 0"
                >
                  <div class="pay_time">{{ item.pay_time }}</div>
                </td>
                <!-- 配送状态 -->
                <td class="Distribution_status">
                  <div class="dis_status" v-if="iitem.status == 0">待发货</div>
                  <div class="dis_status" v-if="iitem.status == 1">已发货</div>
                  <div
                    class="a_edit"
                    v-if="iitem.status == 1"
                    @click="show_logis"
                  >
                    <u>查看物流</u>
                  </div>
                </td>
                <!-- 操作 -->
                <td :rowspan="`${item.list.length}`" v-if="iindex == 0">
                  <div
                    class="a_edit"
                    v-if="item.status <= 2"
                    @click="show_deliver_goods(item)"
                  >
                    <u>发货</u>
                  </div>

                  <div
                    class="a_edit"
                    v-if="item.status == 1"
                    @click="show_edit_order(item)"
                  >
                    <u>修改订单</u>
                  </div>
                </td>
              </template>
            </tr>
            <tr>
              <td colspan="6">
                <div class="adress">
                  <div class="ad_top">
                    <div
                      class="top1 iconfont icon-shouhuodizhi"
                      v-if="
                        item.xgaddress != '' &&
                        item.xgaddress != null &&
                        item.xgaddress != undefined
                      "
                    >
                      {{ item.xgaddress }}
                    </div>
                    <div class="top1 iconfont icon-shouhuodizhi" v-else>
                      {{ item.address }}
                    </div>
                  </div>
                  <div class="ad_bottom">
                    <div
                      class="bt1"
                      v-if="
                        item.xname != '' &&
                        item.xname != null &&
                        item.xname != undefined
                      "
                    >
                      {{ item.xname }}
                    </div>
                    <div class="bt1" v-else>{{ item.address_name }}</div>

                    <div
                      class="bt2"
                      v-if="
                        item.xphone != '' &&
                        item.xphone != null &&
                        item.xphone != undefined
                      "
                    >
                      {{ item.xphone }}
                    </div>
                    <div class="bt2" v-else>{{ item.address_phone }}</div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <el-dialog
        class="bba"
        center
        custom-class="adialog"
        title="发货"
        :visible.sync="deliver_goods"
      >
        <div class="tanchuan">
          <div style="padding: 10px 40px; border-bottom: 1px solid #e8e8e8">
            订单号:{{ consignment_list.ordersn }}
          </div>
          <div class="t_list">
            <div
              class="table_list"
              v-for="(item, index) in consignment_list.list"
              :key="index"
            >
              <div class="tupian">
                <div
                  class="img"
                  :style="{ 'background-image': `url(${item.img_src})` }"
                ></div>
                <div class="goods_info">
                  <div class="Trade_name" :title="item.title">
                    {{ item.title }}
                  </div>
                  <div class="goods_id">商品ID：{{ item.goodsid }}</div>
                  <div class="goods_id">商品属性：{{ item.attribute_title }}</div>
                </div>
              </div>
              <div class="seclect_box">
                <div class="seclect_txt">选择快递：</div>
                <el-select
                  class="select"
                  v-model="item.expresscom"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in Express_type_list"
                    :key="item.value"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="main_txt2">
                <span>快递单号：</span>
                <el-input style="flex: 1" v-model="item.expresssn"></el-input>
              </div>
            </div>
          </div>
          <div class="adress">
            <div class="ad_top">
              <div class="top1 iconfont icon-shouhuodizhi">
                {{ consignment_list.address }}
              </div>
            </div>
            <div class="ad_bottom">
              <div class="bt1">{{ consignment_list.address_name }}</div>
              <div class="bt2">{{ consignment_list.address_phone }}</div>
            </div>
          </div>
        </div>
        <div class="footer_btn">
          <el-button class="bt_cancel" @click="cancel_btn1">取消</el-button>
          <el-button class="bt_confirm" @click="submit_kd">确定</el-button>
        </div>
        <!-- 事件名已经在下面给定了-->
      </el-dialog>
      <el-dialog
        class="bbac"
        center
        custom-class="adialog"
        title="修改订单"
        :visible.sync="edit_order"
      >
        <div class="bba_main">
          <div class="main_txt1">订单号：{{ consignment_list.ordersn }}</div>
          <div class="main_txt1">
            原收件人：{{ consignment_list.address_name }}
          </div>
          <div class="main_txt1">
            原手机号：{{ consignment_list.address_phone }}
          </div>
          <div class="main_txt1">原地址：{{ consignment_list.address }}</div>
          <div class="inp_box">
            <div class="txt">收件人：</div>
            <el-input v-model="address_name" />
          </div>
          <div class="inp_box">
            <div class="txt">手机号：</div>
            <el-input v-model="phone" />
          </div>
          <div class="inp_box">
            <div class="txt">收货地址：</div>
            <el-input v-model="address" />
          </div>
        </div>
        <div class="footer_btn">
          <el-button class="bt_cancel" @click="cancel_btn">取消</el-button>
          <el-button class="bt_confirm" @click="modify">确定</el-button>
          <!-- 事件名已经在下面给定了-->
        </div>
      </el-dialog>
      <page-tab
        :page="page"
        :count="page_count"
        @change="change_page"
      ></page-tab>
    </template>
    <!-- 物流详情页 -->
    <template v-if="show_logistics === 1">
      <div class="logistics_zong">
        <div class="act_icon iconfont icon-zuo" @click="back"></div>
        <div class="log_head">
          <div class="log_hd_title">物流详情</div>
          <div class="log_hd_text1">
            <div class="log_zuo">
              <div class="log_tt">订单编号:2190349730441666526</div>
              <div class="log_tt">付款时间:2021-10-18 10:15:57</div>
            </div>
            <div class="log_right">
              <div class="log_right_top">
                <div class="log_tt">张三李四</div>
                <div class="log_tt">手机号码：13969451311</div>
              </div>
              <div class="log_right_bottom">
                收货地址：广东省广州市番禺区迎星中路骏盈大厦骏盈大路骏
              </div>
            </div>
          </div>
        </div>
        <div class="log_center">
          <div class="cen_title">包裹</div>
          <div class="cen_text">
            <div class="text_left">
              <div class="left_txt">运单号:2190349730441666526</div>
              <div class="left_txt">中通快递：75817892236188</div>
              <div class="left_txt">发货时间:2021-10-18 10:15:57</div>
            </div>
            <div class="text_right">
              <img class="goods_tu" src="../../static/img/wxl_touxiang.jpg" />
              <div class="goods_tt">
                <div class="log_goods_name">
                  商品名称商品名称商品名称商品名称名称商品名称商品名称商品名称...
                </div>
                <div class="log_goods_id">ID：12345678901</div>
                <div class="log_goods_num">X1</div>
              </div>
            </div>
          </div>
        </div>
        <div class="log_footer">
          <div class="foo_title">物流动态</div>
          <div class="foo_text">
            <el-timeline class="timeline" :reverse="reverse">
              <el-timeline-item
                class="el_item"
                color="hsv"
                v-for="(activity, index) in activities"
                :key="index"
                :timestamp="activity.timestamp"
              >
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "../../common/axios";
import PageTab from "../pages/index";

export default {
  components: {
    PageTab,
  },
  data() {
    return {
      tab: 0, //头部选择退款状态
      ord_id: "", //订单号输入框
      gods_id: "", //商品ID输入框
      edit_order: false, // 控制修改订单弹框
      deliver_goods: false, //控制发货弹框
      show_logistics: 0, // 控制物流页面
      phone: "", //手机号
      address: "", //收货地址
      order_id: "", //订单id
      courier_number: "", //快递单号
      address_name: "", //收货人
      express: "申通快递", //选择快递
      consignment_list: [], //打开的数组
      page: 1,
      page_count: 0,
      head_list: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "待发货",
        },
        {
          id: 2,
          name: "已发货",
        },
      ],
      order_list: [
        {
          order_id: 12345685421,
          v1: false, // checkbox是否选中
          Product_id: 12345621, //商品ID
          real_income: 180, // 实收
          freight: "0.00", // 运费
          Buyer_id: 123456, //卖家ID
          Trade_name:
            "商品名称商品名称商品名称商品名称商品名称商品撒旦发生名称商品名sadsadsadsa称商品名称...",
          img_url: require("../../static/img/wxl_touxiang.jpg"), //商品图片
          price: 180, //商品价格
          deliver_num: 180, // 退款金额
          stock: 1, // 商品数量
          order_type: "交易成功", // 订单状态
          refund_reason: "不想要了", // 退款原因
          is_refund: 0, //0:未退款  1：已发起退款申请
          refund_state: "仅退款(未发货)", // 退款状态
          Return_status: "", //退货状态
          Distribution_status: "待发货", // 配送状态
          Express_type: "圆通快递", // 快递类型
          courier_number: "654651515484342", //快递单号
          phone: 13005022230, // 收件人手机号
          addressee: "琪琪", //收件人
          Receiving_address: "广东省广州市番禺区迎星中路骏盈大厦骏盈大路骏", //收货地址
        },
      ],

      Express_type_list: [
        {
          value: "选项1",
          label: "申通快递",
        },
        {
          value: "选项2",
          label: "圆通快递",
        },
        {
          value: "选项3",
          label: "邮政快递",
        },
        {
          value: "选项4",
          label: "极兔快递",
        },
      ],
      reverse: false,
      activities: [
        {
          content:
            "快件已在枣庄潭州签收，签收人：本人。如有疑问请电话联系：15163265865，投诉电话：0632-4034981，您的快递已经投妥。风里来雨里去，只为客官您满意，上有老下有小，赏个好评好不好？【请在评价快递员处帮忙点亮五颗星星哦~】",
          timestamp: "2018-04-15",
        },
        {
          content:
            "枣庄潭州的问天分部李俊瑶[15163265865]正在派件 (95720为中通快递员外呼专属号码，请放心接听)",
          timestamp: "2018-04-13",
        },
        {
          content: "快件已到达枣庄潭州",
          timestamp: "2018-04-11",
        },
        {
          content: "快件离开济宁中专部已发往枣庄潭州",
          timestamp: "2018-04-11",
        },
      ],
    };
  },
  mounted() {
    this.get_list();
  },
  methods: {
    change_page(e) {
      this.page = e.value;
      this.get_list(e.value);
      this.$refs.tree.scrollTop = 0
    },
    //发货确定按钮
    submit_kd() {
      let arr = [];
      let consignment_list = this.consignment_list;
      let id = consignment_list.id;
      let ordersn = consignment_list.ordersn;
      let alist = consignment_list.list;
      alist.map((item) => {
        arr.push({
          id: item.id,
          expresscom: item.expresscom,
          expresssn: item.expresssn,
        });
      });
      let blist = [];
      arr.map((item) => {
        blist.push({
          id: id,
          ordersn: ordersn,
          lid: item.id,
          expresscom: item.expresscom,
          expresssn: item.expresssn,
        });
      });
      let data = {
        list: blist,
      };
      axios.post("/adminshop/order/order_fh", data).then((res) => {
        var reslut = res.data;
        if (res.success) {
          if (reslut.status == 1) {
            console.log(reslut);
            this.deliver_goods = false;
            this.$message({
              message: reslut.msg,
              type: "success",
            });
            this.get_list();
          } else {
            this.$message.error(reslut.msg);
          }
        }
      });
    },
    //获取数据
    get_list(page = 1) {
      let type = 1;
      this.page = page;
      let status = "";
      if (this.tab == 0) {
        status = 0;
      } else if (this.tab == 1) {
        status = 1;
      } else if (this.tab == 2) {
        status = 2;
      }
      let data = {
        status,
        type,
        page,
        ord: this.ord_id,
        title: this.gods_id,
      };
      axios.post("/adminshop/order/index", data).then((res) => {
        var reslut = res.data;
        if (res.success) {
          console.log(reslut);
          this.order_list = reslut.list;
          this.page_count = reslut.pages;
        }
      });
    },
    //头部宝贝状态的tab栏
    change_item(index) {
      this.tab = index;
      this.get_list();
    },
    show_logis() {
      //显示物流详情页
      this.show_logistics = 1;
    },
    // 显示修改订单弹框
    show_edit_order(item) {
      this.edit_order = true;
      this.consignment_list = item;
    },
    cancel_btn() {
      // 修改订单弹框取消按钮事件
      this.edit_order = !this.edit_order;
    },
    //修改订单确认按钮
    modify() {
      let id = this.consignment_list.id;
      let username = this.address_name;
      let phone = this.phone;
      let address = this.address;
      let data = {
        id,
        username,
        phone,
        address,
      };
      axios.post("/adminshop/order/order_edit_address", data).then((res) => {
        var reslut = res.data;
        if (res.success) {
          if (reslut.status == 1) {
            console.log(reslut);
            this.edit_order = false;
            this.$message({
              message: reslut.msg,
              type: "success",
            });
            this.get_list();
          } else {
            this.$message.error(reslut.msg);
          }
        }
      });
    },
    show_deliver_goods(item) {
      this.consignment_list = item;
      this.deliver_goods = true;
    },
    cancel_btn1() {
      // 发货弹框取消按钮事件
      this.deliver_goods = !this.deliver_goods;
    },
    back() {
      this.show_logistics = 0;
    },
    search() {
      this.get_list();
    },
    a(){}
  },
};
</script>

<style scoped>
@import "./deliver_goods.css";

.bba /deep/ .el-dialog__header {
  padding: 15px 0;
  border-bottom: 1px solid #e8e8e8;
}

.bba /deep/ .el-dialog__title {
  font-weight: bold;
  line-height: 0;
}
.bba /deep/ .el-dialog__body {
  padding: 0;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.steps /deep/ .el-step__icon {
  width: 50px;
  height: 50px;
}

.steps /deep/ .el-step__line {
  top: 50%;
  transform: translateY(-50%);
}

.steps /deep/ .is-success {
  color: #a466fd;
  font-weight: bold;
  border-color: #a466fd;
}

.steps /deep/ .is-process {
  color: #999999;
  border-color: #999999;
}

.steps /deep/ .is-status {
  transform: scale(2.5);
}

.timeline /deep/ .el-timeline {
  width: 300px;
}

.timeline /deep/ .el-timeline-item {
  font-size: 15px;
}

.timeline /deep/ .el-timeline-item:nth-child(1) {
  font-weight: bold;
}
</style>
