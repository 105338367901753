<template>
	<div class="complaint_zong">
		<template v-if="show_appeal === 0">
			<div class="head">
				<!-- 头部tab栏 -->
				<div class="hd_tab">
					<div v-for="(item,index) in head_list" :key="index"
						:class="tab===index ? 'head_item_click' : 'head_item'" class="head_item"
						@click="change_item(index)">
						{{item.name}}
					</div>
				</div>
				<!-- 中间搜索框 -->
				<div class="hd_search">
					<div class="search_box">
						<div class="txt">订单号</div>
						<input class="inp" v-model="ord_id" />
					</div>
					<div class="search_box">
						<div class="txt">商品ID</div>
						<input class="inp" v-model="gods_id" />
					</div>
					<div class="search_box">
						<div class="txt">订单状态</div>
						<el-select class="select" v-model="value" placeholder="请选择">
							<el-option v-for="item in options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="search_box">
						<div class="txt"></div>
						<el-button class="search_btn">搜索</el-button>
					</div>
					<div class="clear"></div>
				</div>
				<!-- 批量操作商品 -->
				<!-- <div class="goods_edit">
                    <el-button 
                        class="edit_box" 
                        :disabled='agree_Compensation' 
                        :class="agree_Compensation===false ? 'edit_box1' : 'edit_box'"
                    >
                        同意赔付
                    </el-button>
                    <div class="edit_hou">已选商品(0)</div>
                </div> -->
			</div>
			<div class="bbb">
				<table class="table">
					<!-- 表头 -->
					<thead class="list_hd">
						<th class="one">
							<el-checkbox class="checkbox" @change="handleCheckAllChange" v-model="checkAll">
								宝贝
							</el-checkbox>
						</th>
						<th class="it">赔付金额</th>
						<th class="it">投诉原因</th>
						<th class="it">投诉状态</th>
						<th class="it">申请时间</th>
						<th class="it">操作</th>
					</thead>
					<!-- 表单内容 -->
					<tbody class="list_text" v-for="(item,index) in order_list" :key="index">
						<tr>
							<td class="gds_item">
								<el-checkbox class="checkbox" v-model="item.v1" @change="hra(item.v1)">
									<img class="goods_tu" :src="item.img_url" />
								</el-checkbox>
								<div class="goods_info">
									<div class="Trade_name">{{item.Trade_name}}</div>
									<div class="goods_id">订单号:{{item.order_id}}</div>
									<div class="di">卖家ID：{{item.Buyer_id}}</div>
								</div>
							</td>
							<!-- 赔付金额 -->
							<td>
								<div class="amount">￥{{item.Compensation_num}}</div>
							</td>
							<!-- 投诉原因 -->
							<td class="complaint_reason">
								<div class="reason_text">{{item.complaint_reason}}</div>
							</td>
							<!-- 投诉状态 -->
							<td class="Complaint_status">
								<div class="status_txt">{{item.Complaint_status}}</div>
							</td>
							<!-- 申请时间 -->
							<td class="apply_time">
								<div class="time_txt">{{item.apply_time}}</div>
								<!-- 倒数计时 -->
								<div class=""></div>
							</td>
							<!-- 操作 -->
							<td class="operation">
								<div class="oper_1" v-if="item.Complaint_status === '等待卖家处理'" @click="show_reply_comm">
									<u>同意赔付</u>
								</div>
								<el-dialog class="bba" center custom-class='dialog' title="同意赔付"
									:visible.sync="reply_comm">
									<div class="bba_main">
										<div class="com_price">
											<div class="price_txt">赔付金额：</div>
											<div class="num_time">
												<div class="price_num">￥180</div>
												<!-- <div class="price_time"></div> 倒数计时 -->
											</div>
										</div>
										<div class="com_ord_id">
											<div class="ord_id_txt">订单号：</div>
											<div class="ord_id_num">12345678901</div>
										</div>
										<div class="com_ord_id">
											<div class="ord_id_txt">投诉原因：</div>
											<div class="ord_id_num">发货问题</div>
										</div>
										<div class="com_m">如果同意，将直接退款给买家</div>
										<div class="com_m">如逾期，未处理系统将自动赔付给买家</div>

									</div>
									<div class="footer_btn">
										<el-button class="bt_cancel" @click="cancel_btn">取消</el-button>
										<el-button class="bt_confirm">确定</el-button> <!-- 事件名已经在下面给定了-->
									</div>
								</el-dialog>
								<div class="oper_1" v-if="item.Complaint_status === '等待卖家处理'" @click="go_appeal">
									<u>申诉</u></div>
								<div class="oper_1" v-if="item.Complaint_status === '投诉已撤销'"><u>查看</u></div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- 底部分页 -->
			<div class="footer">
				<div class="previous" @click="reduce">上一页</div>
				<div class="page_list">
					<div class="li_it" :class="page_tab === index ? 'li_it1' : 'li_it' " @click="change_page(index)"
						v-for="(item,index) in page_list" :key="index">
						{{item.page_num}}
					</div>
				</div>
				<div class="next" @click="add">下一页</div>
			</div>
		</template>
		<!-- 申诉页面 -->
		<template v-if="show_appeal === 1">
			<div class="appeal_zong">
				<div class="hd">
					<div class="hd_top">来自<span style="color:#333333">张三</span>的投诉（投诉编号12345678901）</div>
					<div class="hd_bt">
						<div class="bt_zuo">
							<div class="zuo_txt1">投诉时间：<span style="color:#333333">2021.12.27</span></div>
							<div class="zuo_txt1">投诉原因：<span style="color:#333333">发货问题</span></div>
							<div class="zuo_txt1">投诉说明：<span style="color:#333333">投诉说明投诉说明投诉说明投诉说明诉说明诉诉说
									诉说明诉说明诉说明诉说明诉说明诉说明诉说明说明</span></div>
						</div>
						<div class="bt_you">
							<div class="zuo_txt1">联系人：<span style="color:#333333">夏先生</span></div>
							<div class="zuo_txt1">联系邮箱：<span
									style="color:#333333">1246481264512@163.com1246481264512@163.com</span></div>
							<div class="zuo_txt1">联系电话：<span style="color:#333333">123456789211</span></div>
						</div>
					</div>
				</div>
				<div class="ccc">
					<table class="table1">
						<!-- 表头 -->
						<thead class="list_hd1">
							<th class="two">订单号：12345678901</th>
							<th class="it1">申诉状态</th>
							<th class="it1">操作</th>
						</thead>
						<!-- 表单内容 -->
						<tbody class="list_text1">
							<tr>
								<td class="gds_item1">
									<img class="goods_tu1" src="../../static/img/wxl_touxiang.jpg" />
									<div class="goods_info">
										<div class="Trade_name">商品名称商品名称商品名称商品名称商品名称商品撒旦发生名称商品名sadsadsadsa称商品名称...</div>
										<div class="goods_id">订单号:12345685421</div>
										<div class="di">卖家ID：1312312</div>
									</div>
								</td>
								<!-- 申诉状态 -->
								<td class="gds_item2">
									<div class="item2_txt">投诉审核通过</div>
								</td>
								<!-- 操作 -->
								<td class="gds_item3">
									<el-popover placement="bottom" width="200" trigger="hover">
										<div class="uh">
											<el-timeline class="timeline" :reverse="reverse">
												<el-timeline-item class="el_item" :color="activity.color"
													v-for="(activity, index) in activities" :key="index"
													:timestamp="activity.timestamp">
													{{activity.content}}
												</el-timeline-item>
											</el-timeline>
										</div>
										<div class="item3_txt" slot="reference">查看进展</div>
									</el-popover>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="com_reason">
					<div class="reason_zu">
						<div class="zu_txt1">投诉原因：</div>
						<div class="zu_txt2">发货问题</div>
					</div>
					<div class="reason_zu1">
						<div class="zu1_txt">申诉说明：</div>
						<el-input class="inp" type="textarea" :rows="4" placeholder="请输入申诉说明" v-model="textarea">
						</el-input>
					</div>
					<div class="reason_zu2">
						<div class="zu2_txt1">申诉图片：</div>
						<div class="zu2_tu_list">
							<el-upload class="upload" action="https://jsonplaceholder.typicode.com/posts/"
								list-type="picture-card" :on-preview="handlePictureCardPreview"
								:on-remove="handleRemove">
								<i class="el-icon-plus"></i>
							</el-upload>
						</div>
					</div>
				</div>
				<div class="com_bottom">
					<el-button class="bottom_btn" @click="show_appeal_result">提交申请</el-button>
				</div>
			</div>
		</template>
		<!-- 申诉结果页面 -->
		<template v-if="show_appeal === 2">
			<div class="appeal_result_zong">
				<div class="g_head">
					<div class="hd_title">投诉状态：交易成功</div>
					<div class="hd_center">
						<el-steps class="steps" :space="150" size="larg" :active="3" :align-center='true'
							finish-status="success">
							<el-step title="买家投诉"></el-step>
							<el-step title="卖家申诉"></el-step>
							<el-step title="系统介入"></el-step>
							<el-step title="投诉结果"></el-step>
						</el-steps>
					</div>
				</div>
				<div class="g_main iconfont icon-gantanhao" v-if="jjj===0">系统已介入审核请耐心等待</div>
				<div class="g_main1 iconfont icon-gantanhao" v-if="jjj===1">买家已撤销</div>
				<div class="g_main1 iconfont icon-gantanhao" v-if="jjj===2">投诉成立，系统自动赔付给买家</div>
				<div class="g_histroy" v-if="jjj===1 || jjj===2">
					<div class="his_top">投诉历史</div>
					<div class="his_bottom">
						<div class="bt_item">
							<img class="img_youxiang" src="../../static/img/wxl_touxiang.jpg" />
							<div class="ussd">
								<div class="kk">
									<div class="use_id">ID :12345678</div>
									<div class="comm_time">2021.12.19 12:23</div>
								</div>
								<div class="use_txt" v-if="jjj===1">买家撤销投诉，投诉完结。</div>
								<div class="use_txt" v-if="jjj===2">投诉成立，系统自动赔付给买家。</div>
							</div>
						</div>
						<div class="bt_item">
							<img class="img_youxiang" src="../../static/img/wxl_touxiang.jpg" />
							<div class="ussd">
								<div class="kk">
									<div class="use_id">ID :12345678</div>
									<div class="comm_time">2021.12.19 12:23</div>
								</div>
								<div class="use_txt">卖家的申述卖家的申述卖家的申述卖家的申述卖家的申述。</div>
								<img class="appeal_tu" src="../../static/img/wxl_touxiang.jpg" />
								<img class="appeal_tu" src="../../static/img/wxl_touxiang.jpg" />
							</div>
						</div>
						<div class="bt_item">
							<img class="img_youxiang" src="../../static/img/wxl_touxiang.jpg" />
							<div class="ussd">
								<div class="kk">
									<div class="use_id">ID :12345678</div>
									<div class="comm_time">2021.12.19 12:23</div>
								</div>
								<div class="use_txt">买家发起投诉，投诉原因：发货问题。
									投诉说明：投诉说明投诉说明投诉说明投诉说明投诉说明投诉说明投诉说明投诉说明投诉说明投诉说明投诉说明投诉说明投诉说明投诉说明</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				jjj: 1, // 临时决定投诉状态的值
				tab: 0, //头部选择退款状态
				ord_id: '', //订单号输入框
				gods_id: '', //商品ID输入框
				value: '', // 订单状态选中的值
				agree_Compensation: true, // 控制同意赔付按钮
				page_tab: 0, // 页码
				checkAll: false, // 宝贝的checkbox
				show_appeal: 0, //是否显示申诉界面
				textarea: '',
				reply_comm: false, //控制同意赔付弹框
				head_list: [{
						id: 1,
						name: '投诉'
					},
					{
						id: 2,
						name: '发货问题'
					},
					{
						id: 3,
						name: '邮费问题'
					},
					{
						id: 4,
						name: '承诺未履行'
					},
					{
						id: 5,
						name: '骚扰他人'
					},
					{
						id: 6,
						name: '其他'
					},
				],
				options: [{
						value: '选项1',
						label: '等待卖家处理'
					},
					{
						value: '选项2',
						label: '等待平台处理'
					},
					{
						value: '选项3',
						label: '投诉已撤销'
					},
					{
						value: '选项4',
						label: '投诉成立'
					},
					{
						value: '选项5',
						label: '投诉不成立'
					},
				],
				page_list: [{
						id: 1,
						page_num: '1'
					},
					{
						id: 2,
						page_num: '2'
					},
					{
						id: 3,
						page_num: '3'
					}, {
						id: 4,
						page_num: '4'
					}
				],
				order_list: [{
						order_id: 12345685421,
						v1: false, // checkbox是否选中
						Product_id: 12345621, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //买家ID
						Trade_name: '商品名称商品名称商品名称商品名称商品名称商品撒旦发生名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "交易成功", // 订单状态
						refund_reason: '不想要了', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "仅退款(未发货)", // 退款状态
						Return_status: '', //退货状态
						Distribution_status: '已发货', // 配送状态
						Express_type: '邮政快递', // 快递类型
						courier_number: '654651515484342', //快递单号
						phone: 13005001822, // 收件人手机号
						addressee: '龟仙人', //收件人
						Receiving_address: '广东省广州市番禺区迎星中路骏盈大厦骏盈大路骏', //收货地址
						first_evaluate: '物流满分包装满分快递心物流满分包装满分快满分购物让人开心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递满分购物让人开心', //买家评论
						first_evaluate_time: '2021-10-11 15:12', //初次评价时间
						Additional_evaluation: '物流满分包装满分快递满分购物让人开心物流满分包装满分快递满分购物流满分包装满分快递满分购物让人开心', //追加评价
						Additional_evaluation_time: '2021-10-11 15:12', // 追加评价时间
						Recoverable_time: '30', // 可回复剩余时间
						v2: true, // 买家是否已评价
						v3: false, //卖家是否已回复评价
						v4: true, // 是否有追加评论
						Compensation_num: 180, //赔付金额
						complaint_reason: '发货问题', //投诉原因
						Complaint_status: '等待卖家处理', //投诉状态
						apply_time: '2021.12.27 13:47', // 投诉申请时间
					},
					{
						order_id: 12345685421,
						v1: false, // checkbox是否选中
						Product_id: 12345621, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //买家ID
						Trade_name: '商品名称商品名称商品名称商品名称商品名称商品撒旦发生名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "交易成功", // 订单状态
						refund_reason: '不想要了', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "仅退款(未发货)", // 退款状态
						Return_status: '', //退货状态
						Distribution_status: '已发货', // 配送状态
						Express_type: '邮政快递', // 快递类型
						courier_number: '654651515484342', //快递单号
						phone: 13005001822, // 收件人手机号
						addressee: '龟仙人', //收件人
						Receiving_address: '广东省广州市番禺区迎星中路骏盈大厦骏盈大路骏', //收货地址
						first_evaluate: '物流满分包装满分快递心物流满分包装满分快满分购物让人开心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递满分购物让人开心', //买家评论
						first_evaluate_time: '2021-10-11 15:12', //初次评价时间
						Additional_evaluation: '物流满分包装满分快递满分购物让人开心物流满分包装满分快递满分购物流满分包装满分快递满分购物让人开心', //追加评价
						Additional_evaluation_time: '2021-10-11 15:12', // 追加评价时间
						Recoverable_time: '30', // 可回复剩余时间
						v2: true, // 买家是否已评价
						v3: false, //卖家是否已回复评价
						v4: false, // 是否有追加评论
						Compensation_num: 180, //赔付金额
						complaint_reason: '邮费问题', //投诉原因
						Complaint_status: '投诉已撤销', //投诉状态
						apply_time: '2021.12.27 13:47', // 投诉申请时间
					},
					{
						order_id: 12345685433,
						v1: false, // checkbox是否选中
						Product_id: 12345623, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //卖家ID
						Trade_name: '商品名称商品名称商品名称商品名称商品名称阿斯顿撒旦商品名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "待支付", // 订单状态
						refund_reason: '协商一致退货', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "退款(退货)", // 退款状态
						Return_status: '已经揽收', //退货状态
						Distribution_status: '已发货', // 配送状态
						Express_type: '极兔快递', // 快递类型
						courier_number: '654651515484342', //快递单号
						phone: 13005054623, // 收件人手机号
						addressee: '克林', //收件人
						Receiving_address: '广东省广州市番禺区迎星中路骏盈大厦骏盈大路骏', //收货地址
						first_evaluate: '物流满分包装满分快递心物流满分包装满分快满分购物让人开心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递满分购物让人开心', //买家评论
						first_evaluate_time: '2021-10-11 15:12', //初次评价时间
						Additional_evaluation: '', //追加评价
						Additional_evaluation_time: '2021-10-11 15:12', // 追加评价时间
						Recoverable_time: '30', // 可回复剩余时间
						v2: false, // 买家是否已评价
						v3: false, //卖家是否已回复评价
						v4: false, // 是否有追加评论
						Compensation_num: 180, //赔付金额
						complaint_reason: '发货问题', //投诉原因
						Complaint_status: '等待卖家处理', //投诉状态
						apply_time: '2021.12.27 13:47', // 投诉申请时间
					},
					{

						order_id: 12345685422,
						v1: false, // checkbox是否选中
						Product_id: 12345621, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //卖家ID
						Trade_name: '商品名称商品名称商品名称商品名称阿斯顿撒旦品名称商品名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "交易取消", // 订单状态
						refund_reason: '不想要了', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "退款(退货)", // 退款状态
						Return_status: '已入库', //退货状态
						Distribution_status: '待发货', // 配送状态
						Express_type: '中通快递', // 快递类型
						courier_number: '654651515484342', //快递单号
						phone: 13005001888, // 收件人手机号
						addressee: '贝吉塔', //收件人
						Receiving_address: '广东省广州市番禺区迎星中路骏盈大厦骏盈大路骏', //收货地址
						first_evaluate: '物流满分包装满分快递心物流满分包装满分快满分购物让人开心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递满分购物让人开心', //买家评论
						first_evaluate_time: '2021-10-11 15:12', //初次评价时间
						Additional_evaluation: '物流满分包装满分快递满分购物让人开心物流满分包装满分快递满分购物流满分包装满分快递满分购物让人开心', //追加评价
						Additional_evaluation_time: '2021-10-11 15:12', // 追加评价时间
						Recoverable_time: '30', // 可回复剩余时间
						v2: true, // 买家是否已评价
						v3: true, //卖家是否已回复评价
						v4: true, // 是否有追加评论
						Compensation_num: 180, //赔付金额
						complaint_reason: '邮费问题', //投诉原因
						Complaint_status: '投诉已撤销', //投诉状态
						apply_time: '2021.12.27 13:47', // 投诉申请时间
					},
					{
						order_id: 12345685444,
						v1: false, // checkbox是否选中
						Product_id: 12345623, //商品ID
						real_income: 180, // 实收
						freight: '0.00', // 运费
						Buyer_id: 123456, //卖家ID
						Trade_name: '商品名称商品名称商品名撒大苏打称商品名称商品名称商品名称商品名sadsadsadsa称商品名称...',
						img_url: require('../../static/img/wxl_touxiang.jpg'), //商品图片
						price: 180, //商品价格
						deliver_num: 180, // 退款金额
						stock: 1, // 商品数量
						order_type: "交易成功", // 订单状态
						refund_reason: '协商一致退货', // 退款原因
						is_refund: 0, //0:未退款  1：已发起退款申请
						refund_state: "退款(退货)", // 退款状态
						Return_status: '等待卖家同意', //退货状态
						Distribution_status: '待发货', // 配送状态
						Express_type: '中通快递', // 快递类型
						courier_number: '654651515484342', //快递单号
						phone: 13005001830, // 收件人手机号
						addressee: '张伯雪影', //收件人
						Receiving_address: '广东省广州市番禺区迎星中路骏盈大厦骏盈大路骏', //收货地址
						first_evaluate: '物流满分包装满分快递心物流满分包装满分快满分购物让人开心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递心物流满分包装满分快递满分购物流满分包装满分快递满分购物让人开心', //买家评论
						first_evaluate_time: '2021-10-11 15:12', //初次评价时间
						Additional_evaluation: '物流满分包装满分快递满分购物让人开心物流满分包装满分快递满分购物流满分包装满分快递满分购物让人开心', //追加评价
						Additional_evaluation_time: '2021-10-11 15:12', // 追加评价时间
						Recoverable_time: '30', // 可回复剩余时间
						v2: false, // 买家是否已评价
						v3: true, //卖家是否已回复评价
						v4: false, // 是否有追加评论
						Compensation_num: 180, //赔付金额
						complaint_reason: '发货问题', //投诉原因
						Complaint_status: '等待卖家处理', //投诉状态
						apply_time: '2021.12.27 13:47', // 投诉申请时间
					},
				],
				reverse: false,
				activities: [{
						content: '投诉发起',
						timestamp: '2018-04-15',
						color: '#725CFD'
					},
					{
						content: '投诉审核通过',
						timestamp: '2018-04-13',
						color: '#725CFD'
					},
					{
						content: '卖家申诉',
						timestamp: '2018-04-11',
						color: '#725CFD'
					},
				]
			}
		},
		methods: {
			change_item(index) { //头部宝贝状态的tab栏
				console.log('index', index)
				this.tab = index
			},
			change_page(index) { // 页码
				this.page_tab = index
			},
			reduce() { // 上一页按钮
				if (this.page_tab > 0) {
					this.page_tab -= 1
				}
			},
			add() { // 下一页按钮
				if (this.page_tab >= 0 && this.page_tab <= 2) {
					this.page_tab += 1
				}
			},
			handleCheckAllChange(val) { // 点击全选，
				console.log('val', val)
				if (!this.checkAll) {
					this.order_list.forEach((item) => {
						item.v1 = false
					})
				} else {
					this.order_list.forEach((item) => {
						item.v1 = true
					})
				}
			},
			hra(e) { //选中其中一个退款订单，触发相对应订单能操作的按钮
				console.log('v1', e)

				//如果选择了全部宝贝，上方的宝贝checkbox会勾选
				let aa = this.order_list.filter(item => item.v1 === true)
				console.log(aa.length === this.order_list.length)
				if (aa.length === this.order_list.length) {
					this.checkAll = true

				} else {
					this.checkAll = false
				}



			},
			go_appeal() { //显示申诉页面
				this.show_appeal = 1
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			show_appeal_result() { //显示申诉结果页面
				this.show_appeal = 2
			},
			show_reply_comm() { // 显示同意赔付弹框
				this.reply_comm = !this.reply_comm
			},
			cancel_btn() { //同意赔付弹框取消按钮事件
				this.reply_comm = !this.reply_comm
			},
			// confirm_btn(){ //同意赔付弹框确定按钮事件

			// },
		}
	}
</script>

<style scoped>
	@import './complaint.css';

	.upload /deep/ .el-upload--picture-card {
		width: 100px;
		height: 100px;
		line-height: 100px;
	}

	.upload /deep/ .el-upload-list__item {
		width: 100px;
		height: 100px;
	}

	.timeline /deep/ .el-timeline {
		width: 300px;
	}

	.timeline /deep/ .el-timeline-item {
		font-size: 15px;
		font-weight: bold;
	}

	.steps /deep/ .el-step__icon {
		width: 50px;
		height: 50px;
	}

	.steps /deep/ .el-step__line {
		top: 50%;
		transform: translateY(-50%);
	}

	.steps /deep/ .is-success {
		color: #A466FD;
		font-weight: bold;
		border-color: #A466FD;
	}

	.steps /deep/ .is-process {
		color: #999999;
		border-color: #999999;
	}

	.steps /deep/ .is-status {
		transform: scale(2.5);
	}

	.bba /deep/ .el-dialog__header {
		padding: 15px 0;
	}

	.bba /deep/ .el-dialog__title {
		font-weight: bold;
		line-height: 0;
	}
</style>
