<template>
  <div class="preview-box" v-if="is_preview">
    <div class="preview-bg"></div>
    <span class="el-image-viewer__btn el-image-viewer__close" @click="close"><i class="el-icon-close"></i></span>
    <span class="el-image-viewer__btn el-image-viewer__prev" @click="on_prev" v-if="src_list.length>1"><i class="el-icon-arrow-left"></i></span>
    <span class="el-image-viewer__btn el-image-viewer__next" @click="on_next" v-if="src_list.length>1"><i class="el-icon-arrow-right"></i></span>
    <div class="preview-com" v-if="src_list.length > 0">
        
        <video v-if="src_list[qindex].type==1" autoplay class="preview-video" controls >
            <source :src="src_list[qindex].video_src" type="video/mp4">
            Your browser does not support the video TAB.
        </video>
        <div class="preview-dimg"
        v-else
        @mousewheel.prevent="rollImg"
        @mousedown.stop="start"
        @mouseup.stop="stop"
        @mousemove.stop="move">
            <img
                draggable="true"
                class="preview-img"
                :style="[
                { transform: `scale(${img_scale}) rotate(${img_rotate}deg)` },
                { 'margin-left': `${img_left}px` },
                { 'margin-top': `${img_top}px` },
                ]"
                @dragstart.prevent
                :src="src_list[qindex].img_src"
            />
            <div class="preimg_action">
                <div class="preimg_icon">
                    <i class="el-icon-zoom-out" @click="collImg(0)"></i>
                    <i class="el-icon-zoom-in" @click="collImg(1)"></i>
                    <i class="el-icon-house" @click="huiwei"></i>
                    <i class="el-icon-refresh-left" @click="rotatImg(0)"></i>
                    <i class="el-icon-refresh-right" @click="rotatImg(1)"></i>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qindex:0,
      src_list:[ ],
      img_scale: 1,
      img_x: 0,
      img_y: 0,
      img_rotate: 0,
      img_left: 0,
      img_top: 0,
      x0: 0,
      y0: 0,
      x1: 0,
      y1: 0,
      canDrag: false,
      is_preview:false,
    };
  },
  methods: {
    start(e) {
      //鼠标左键点击
      if (e.button == 0) {
        this.canDrag = true;
        //记录鼠标指针位置
        this.x0 = e.clientX;
        this.y0 = e.clientY;
      }
    },
    stop() {
      this.canDrag = false;
    },
    move(e) {
      if (this.canDrag == true) {
        this.x1 = e.clientX;
        this.y1 = e.clientY;
        let x = this.x1 - this.x0;
        let y = this.y1 - this.y0;
        
        this.img_left += x;
        this.img_top += y;
        this.x0 = this.x1;
        this.y0 = this.y1;
      }
    },
    huiwei(){
        this.img_scale=1;
        this.img_x=0;
        this.img_y=0;
        this.img_rotate=0;
        this.img_left=0;
        this.img_top=0;
        this.x0=0;
        this.y0=0;
        this.x1=0;
        this.y1=0;
        this.canDrag=false;
    },

    rollImg(e) {
      let deltaY = e.deltaY;
      if (deltaY > 0) {
        if (this.img_scale > 0.5) {
          this.img_scale -= 0.05;
        }
      } else {
        if (this.img_scale < 3) {
          this.img_scale += 0.05;
        }
      }
    },
    rotatImg(type){
        let img_rotate = this.img_rotate;
        if(type==1){
            img_rotate += 90;
        }else{
            img_rotate -= 90;
        }
        if(img_rotate>=360){
            img_rotate = 0;
        }
        this.img_rotate = img_rotate;
    },
    collImg(type){
        if(type==1){
            if (this.img_scale < 3) {
                this.img_scale += 0.2;
            }
        }else{
            if (this.img_scale > 0.5) {
                this.img_scale -= 0.2;
            }
        }
    },
    close(){
        this.is_preview = false;

        this.src_list = [];
        this.qindex = 0;
        this.img_scale=1;
        this.img_x=0;
        this.img_y=0;
        this.img_rotate=0;
        this.img_left=0;
        this.img_top=0;
        this.x0=0;
        this.y0=0;
        this.x1=0;
        this.y1=0;
        this.canDrag=false;
    },
    on_prev(){
        this.huiwei();
        let index = this.qindex;
        if(index<=0){
            return;
        }
        this.qindex--;
    },
    on_next(){
        this.huiwei();
        let index = this.qindex;
        if(index>=this.src_list.length-1){
            return;
        }
        this.qindex++;
    },
    show(src_list,index){
        console.log(src_list,index)
        let qindex = 0;
        let type = 0;
        let is_inx = 0;
        if(src_list!=undefined && src_list!=null && src_list!="" && src_list.length>0){
            type = 1;
            for(var i = 0;i<src_list.length;i++){
                if(src_list[i].type==undefined || src_list[i].type==null){
                    type = 0;
                    break;
                }
                if(src_list[i].img_src==undefined || src_list[i].img_src==null){
                    type = 0;
                    break;
                }
                if(src_list[i].video_src==undefined || src_list[i].video_src==null){
                    type = 0;
                    break;
                }
                if(i==index && is_inx==0){
                    is_inx = 1;
                    qindex = index;
                }else{
                    if(src_list[i].img_src!="" && src_list[i].img_src==index && is_inx==0){
                        is_inx = 1;
                        qindex = i;
                    }
                    if(src_list[i].video_src!="" && src_list[i].video_src==index && is_inx==0){
                        is_inx = 1;
                        qindex = i;
                    }
                }
            }
        }
        if(type==1){
            this.src_list = src_list;
            this.qindex = qindex;
            this.is_preview = true;
        }
    },
  },
};
</script>

<style scoped>
@import "./index.css";
</style>